import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Divider, InputNumber, message, Slider, Switch, Typography } from 'antd';
import { useEffect, useState, useRef } from 'react';
import Flex from '../../components/flex';
import repository from '../../repository';
import ECharts from 'echarts-for-react';
import { themeName } from '../../helper/echartTheme';
import settingStore from '../../stores/settingStore';
import { observer } from 'mobx-react';

enum MagicValueType {
    WORKER_COUNT = 'WORKER_COUNT',
    SCHEDULE_MAX_FOLLOW_WORKFLOW = 'SCHEDULE_MAX_FOLLOW_WORKFLOW',
    DIVIDE_RATE = 'DIVIDE_RATE',
    SCHEDULE_MAX_WORKFLOW = 'SCHEDULE_MAX_WORKFLOW',
    SETUP_WORKER_SPEED = 'SETUP_WORKER_SPEED',
    AGING_WEIGHT = 'AGING_WEIGHT',
    SETTING_WEIGHT = 'SETTING_WEIGHT',
    UNLOCK_WEIGHT = 'UNLOCK_WEIGHT',

    // 1 ~ 150
    SCHEDULE_LIMIT_LIKE_GROUP1 = 'SCHEDULE_LIMIT_LIKE_GROUP1',
    // 151 ~ 400
    SCHEDULE_LIMIT_LIKE_GROUP2 = 'SCHEDULE_LIMIT_LIKE_GROUP2',
    // 401 ~ 800
    SCHEDULE_LIMIT_LIKE_GROUP3 = 'SCHEDULE_LIMIT_LIKE_GROUP3',
    // 801 ~
    SCHEDULE_LIMIT_LIKE_DEFAULT = 'SCHEDULE_LIMIT_LIKE_DEFAULT',
    // 1 ~ 100
    SCHEDULE_LIMIT_FOLLOW_GROUP1 = 'SCHEDULE_LIMIT_FOLLOW_GROUP1',
    // 101 ~ 300
    SCHEDULE_LIMIT_FOLLOW_GROUP2 = 'SCHEDULE_LIMIT_FOLLOW_GROUP2',
    // 301 ~ 600
    SCHEDULE_LIMIT_FOLLOW_GROUP3 = 'SCHEDULE_LIMIT_FOLLOW_GROUP3',
    // 601 ~
    SCHEDULE_LIMIT_FOLLOW_DEFAULT = 'SCHEDULE_LIMIT_FOLLOW_DEFAULT',
}

const EditButton = styled.button`
width: 40px;
height: 40px;
border-radius: 999px;
border: solid 1px #c5c5c5;
background-color: #ffffff;
transition: all 0.2s ease;

:hover {
    background-color: #f5f5f5;
}
`;

export const Interval = {
    SETUP_WORKER_SPEED: {
        min: 0,
        max: 10,
    },
    WORKER_COUNT: {
        min: 0,
        max: 15,
    },
    SCHEDULE_MAX_WORKFLOW: {
        min: 3,
        max: 5,
    },
    SCHEDULE_MAX_FOLLOW_WORKFLOW: {
        min: 0,
        max: 3,
    },
    DIVIDE_RATE: {
        min: 0,
        max: 100,
    },
    AGING_WEIGHT: {
        min: 0,
        max: 30,
    },
    SETTING_WEIGHT: {
        min: 0,
        max: 30,
    },
    UNLOCK_WEIGHT: {
        min: 0,
        max: 30,
    },
    MAX_WORKER_COUNT: {
        min: 0,
        max: 10,
    },
    SCHEDULE_LIMIT_LIKE_GROUP1: {
        min: 0,
        max: 50,
    },
    SCHEDULE_LIMIT_LIKE_GROUP2: {
        min: 0,
        max: 50,
    },
    SCHEDULE_LIMIT_LIKE_GROUP3: {
        min: 0,
        max: 50,
    },
    SCHEDULE_LIMIT_LIKE_DEFAULT: {
        min: 0,
        max: 50,
    },
    SCHEDULE_LIMIT_FOLLOW_GROUP1: {
        min: 0,
        max: 50,
    },
    SCHEDULE_LIMIT_FOLLOW_GROUP2: {
        min: 0,
        max: 50,
    },
    SCHEDULE_LIMIT_FOLLOW_GROUP3: {
        min: 0,
        max: 50,
    },
    SCHEDULE_LIMIT_FOLLOW_DEFAULT: {
        min: 0,
        max: 50,
    },
};

type Values = {
    [key in MagicValueType]?: number;
};

const Setting = observer(() => {
    const [ magicValues, setMagicValues ] = useState<Values>();
    const [ editingField, setEditingField ] = useState<MagicValueType>();
    const [ loading, setLoading ] = useState<boolean>(false);

    const handleValues = (value: Values) => {
        setMagicValues(prev => ({
            ...prev,
            ...value,
        }));
    };

    useEffect(() => {
        if (settingStore.magicValues) {
            setMagicValues(settingStore.magicValues);
        }
    }, [ settingStore.magicValues ]);

    useEffect(() => {
        settingStore.initialize();
    }, []);

    return (
        <div style={{ padding: '50px 20px 0 50px' }}>
            <div style={{ maxWidth: 400 }}>

                <Typography.Title level={3}>프로덕션 워커</Typography.Title>
                <div>
                    <Typography.Title level={5}>1. 선입선출 비율</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }} >
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.DIVIDE_RATE}
                                min={Interval.DIVIDE_RATE.min}
                                max={Interval.DIVIDE_RATE.max}
                                marks={{
                                    [Interval.DIVIDE_RATE.min]: `${Interval.DIVIDE_RATE.min}%`,
                                    [Interval.DIVIDE_RATE.max]: `${Interval.DIVIDE_RATE.max}%`,
                                }}
                                onChange={value => handleValues({ DIVIDE_RATE: value })}
                                value={magicValues?.DIVIDE_RATE}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.DIVIDE_RATE}
                                min={Interval.DIVIDE_RATE.min}
                                max={Interval.DIVIDE_RATE.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.DIVIDE_RATE}
                                onChange={value => handleValues({ DIVIDE_RATE: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.DIVIDE_RATE) {
                                    if (
                                        settingStore.magicValues?.DIVIDE_RATE !== magicValues?.DIVIDE_RATE &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.DIVIDE_RATE} -> ${magicValues?.DIVIDE_RATE}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.DIVIDE_RATE, `${magicValues?.DIVIDE_RATE}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.DIVIDE_RATE);
                                }
                            }}>
                                { editingField === MagicValueType.DIVIDE_RATE
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                    <Typography.Title level={5}>2. 워커 개수</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }} >
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.WORKER_COUNT}
                                min={Interval.WORKER_COUNT.min}
                                max={Interval.WORKER_COUNT.max}
                                step={1}
                                marks={{
                                    [Interval.WORKER_COUNT.min]: Interval.WORKER_COUNT.min,
                                    [Interval.WORKER_COUNT.max]: Interval.WORKER_COUNT.max,
                                }}
                                onChange={value => handleValues({ WORKER_COUNT: value })}
                                value={magicValues?.WORKER_COUNT}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.WORKER_COUNT}
                                min={Interval.WORKER_COUNT.min}
                                max={Interval.WORKER_COUNT.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.WORKER_COUNT}
                                onChange={value => handleValues({ WORKER_COUNT: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.WORKER_COUNT) {
                                    if (
                                        settingStore.magicValues?.WORKER_COUNT !== magicValues?.WORKER_COUNT &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.WORKER_COUNT} -> ${magicValues?.WORKER_COUNT}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.WORKER_COUNT, `${magicValues?.WORKER_COUNT}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.WORKER_COUNT);
                                }
                            }}>
                                { editingField === MagicValueType.WORKER_COUNT
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                    <Typography.Title level={5}>3. 스케줄당 워크플로우 개수</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }} >
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.SCHEDULE_MAX_WORKFLOW}
                                min={Interval.SCHEDULE_MAX_WORKFLOW.min}
                                max={Interval.SCHEDULE_MAX_WORKFLOW.max}
                                step={1}
                                marks={{
                                    [Interval.SCHEDULE_MAX_WORKFLOW.min]: Interval.SCHEDULE_MAX_WORKFLOW.min,
                                    [Interval.SCHEDULE_MAX_WORKFLOW.max]: Interval.SCHEDULE_MAX_WORKFLOW.max,
                                }}
                                onChange={value => handleValues({ SCHEDULE_MAX_WORKFLOW: value })}
                                value={magicValues?.SCHEDULE_MAX_WORKFLOW}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.SCHEDULE_MAX_WORKFLOW}
                                min={Interval.SCHEDULE_MAX_WORKFLOW.min}
                                max={Interval.SCHEDULE_MAX_WORKFLOW.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.SCHEDULE_MAX_WORKFLOW}
                                onChange={value => handleValues({ SCHEDULE_MAX_WORKFLOW: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.SCHEDULE_MAX_WORKFLOW) {
                                    if (
                                        settingStore.magicValues?.SCHEDULE_MAX_WORKFLOW !== magicValues?.SCHEDULE_MAX_WORKFLOW &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.SCHEDULE_MAX_WORKFLOW} -> ${magicValues?.SCHEDULE_MAX_WORKFLOW}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.SCHEDULE_MAX_WORKFLOW, `${magicValues?.SCHEDULE_MAX_WORKFLOW}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.SCHEDULE_MAX_WORKFLOW);
                                }
                            }}>
                                { editingField === MagicValueType.SCHEDULE_MAX_WORKFLOW
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                    <Typography.Title level={5}>4. 스케줄당 팔로워 최대 개수</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }} >
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.SCHEDULE_MAX_FOLLOW_WORKFLOW}
                                min={Interval.SCHEDULE_MAX_FOLLOW_WORKFLOW.min}
                                max={Interval.SCHEDULE_MAX_FOLLOW_WORKFLOW.max}
                                step={1}
                                marks={{
                                    [Interval.SCHEDULE_MAX_FOLLOW_WORKFLOW.min]: Interval.SCHEDULE_MAX_FOLLOW_WORKFLOW.min,
                                    [Interval.SCHEDULE_MAX_FOLLOW_WORKFLOW.max]: Interval.SCHEDULE_MAX_FOLLOW_WORKFLOW.max,
                                }}
                                onChange={value => handleValues({ SCHEDULE_MAX_FOLLOW_WORKFLOW: value })}
                                value={magicValues?.SCHEDULE_MAX_FOLLOW_WORKFLOW}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.SCHEDULE_MAX_FOLLOW_WORKFLOW}
                                min={Interval.SCHEDULE_MAX_FOLLOW_WORKFLOW.min}
                                max={Interval.SCHEDULE_MAX_FOLLOW_WORKFLOW.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.SCHEDULE_MAX_FOLLOW_WORKFLOW}
                                onChange={value => handleValues({ SCHEDULE_MAX_FOLLOW_WORKFLOW: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.SCHEDULE_MAX_FOLLOW_WORKFLOW) {
                                    if (
                                        settingStore.magicValues?.SCHEDULE_MAX_FOLLOW_WORKFLOW !== magicValues?.SCHEDULE_MAX_FOLLOW_WORKFLOW &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.SCHEDULE_MAX_FOLLOW_WORKFLOW} -> ${magicValues?.SCHEDULE_MAX_FOLLOW_WORKFLOW}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.SCHEDULE_MAX_FOLLOW_WORKFLOW, `${magicValues?.SCHEDULE_MAX_FOLLOW_WORKFLOW}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.SCHEDULE_MAX_FOLLOW_WORKFLOW);
                                }
                            }}>
                                { editingField === MagicValueType.SCHEDULE_MAX_FOLLOW_WORKFLOW
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                    <ECharts
                        option={{
                            title: {
                                text: '워크플로우 분배 방식',
                                left: 'center'
                            },
                            tooltip: {
                                trigger: 'item'
                            },
                            series: [
                                {
                                    name: '',
                                    type: 'pie',
                                    radius: '50%',
                                    data: [
                                        { value: ((magicValues?.SCHEDULE_MAX_WORKFLOW ?? 0) - (magicValues?.SCHEDULE_MAX_FOLLOW_WORKFLOW ?? 0)), name: '좋아요' },
                                        { value: magicValues?.SCHEDULE_MAX_FOLLOW_WORKFLOW, name: '팔로우' },
                                    ],
                                    label: {
                                        position: 'inner',
                                        fontSize: 14
                                    },
                                    emphasis: {
                                        itemStyle: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                }
                            ]
                        }}
                        theme={themeName}
                        opts={{
                            renderer: 'canvas',
                            width: 'auto',
                        }}
                        style={{ width: 300 }}
                    />
                </div>
            </div>
            <Divider style={{ margin: '40px 0' }} />
            <div style={{ maxWidth: 400 }}>
                <Typography.Title level={3}>파일럿</Typography.Title>
                <Flex justifyContent='space-between' style={{ marginBottom: 40 }}>
                    <Typography.Title level={5} style={{ margin: 0 }}>1. ON/OFF</Typography.Title>
                    <Switch
                        checked={settingStore.canAutoPilot}
                        onChange={checked => {
                            (async () => {
                                setLoading(true);
                                await settingStore.updateAutoPilot(checked);
                                message.success('설정값 변경에 성공하였습니다.');
                                setLoading(false);
                            })();
                        }}
                        defaultChecked={true}
                    />
                </Flex>

                <Flex justifyContent='space-between' style={{ marginBottom: 40 }}>
                    <Typography.Title level={5} style={{ margin: 0 }}>2. 최대 워커 개수</Typography.Title>
                    <Flex>
                        <InputNumber
                            disabled
                            min={Interval.MAX_WORKER_COUNT.min}
                            max={Interval.MAX_WORKER_COUNT.max}
                            style={{ margin: '0 24px', maxWidth: 70 }}
                            value={settingStore.autoPilotParameter?.maxWorkerCount}
                        />
                        <EditButton
                            disabled={!settingStore.canAutoPilot}
                            onClick={async () => {
                                const maxWorkerCount = window.prompt('최대 워커 개수를 입력해주세요.');
                                if (maxWorkerCount) {
                                    const numMaxWorkerCount = parseFloat(maxWorkerCount);
                                    if (!Number.isNaN(numMaxWorkerCount)) {
                                        if (numMaxWorkerCount < Interval.MAX_WORKER_COUNT.min) {
                                            window.alert(`${Interval.MAX_WORKER_COUNT.min} 이상으로 설정해주세요.`);
                                            return;
                                        }

                                        if (numMaxWorkerCount > Interval.MAX_WORKER_COUNT.max) {
                                            window.alert(`${Interval.MAX_WORKER_COUNT.max} 이하로 설정해주세요.`);
                                            return;
                                        }

                                        const _numMaxWorkerCount = Math.floor(numMaxWorkerCount * 10) / 10;

                                        setLoading(true);
                                        await settingStore.updateAutoPilotParameters('maxWorkerCount', _numMaxWorkerCount);
                                        message.success('설정값 변경에 성공하였습니다.');
                                        setLoading(false);

                                        return;
                                    }
                                }

                                window.alert('올바르지 않은 형식입니다.');
                            }}
                        >
                            <EditOutlined />
                        </EditButton>
                    </Flex>
                </Flex>
                <Flex justifyContent='space-between' style={{ marginBottom: 40 }}>
                    <Typography.Title level={5} style={{ margin: 0 }}>3. 구독 좋아요 자동화</Typography.Title>
                    <Switch
                        disabled={!settingStore.canAutoPilot}
                        checked={settingStore.autoPilotParameter?.insertLikeSubscription}
                        onChange={checked => {
                            (async () => {
                                setLoading(true);
                                await settingStore.updateAutoPilotParameters('insertLikeSubscription', checked);
                                message.success('설정값 변경에 성공하였습니다.');
                                setLoading(false);
                            })();
                        }}
                        defaultChecked={true}
                    />
                </Flex>
                <Flex justifyContent='space-between' style={{ marginBottom: 40 }}>
                    <Typography.Title level={5} style={{ margin: 0 }}>4. 구독 팔로우 자동화</Typography.Title>
                    <Switch
                        disabled={!settingStore.canAutoPilot}
                        checked={settingStore.autoPilotParameter?.insertFollowSubscription}
                        onChange={checked => {
                            (async () => {
                                setLoading(true);
                                await settingStore.updateAutoPilotParameters('insertFollowSubscription', checked);
                                message.success('설정값 변경에 성공하였습니다.');
                                setLoading(false);
                            })();
                        }}
                        defaultChecked={true}
                    />
                </Flex>
                <Flex justifyContent='space-between' style={{ marginBottom: 40 }}>
                    <Typography.Title level={5} style={{ margin: 0 }}>5. 구독 팔로우 부스트모드</Typography.Title>
                    <Switch
                        disabled={!settingStore.canAutoPilot}
                        checked={settingStore.autoPilotParameter?.boostMode}
                        onChange={checked => {
                            (async () => {
                                setLoading(true);
                                await settingStore.updateAutoPilotParameters('boostMode', checked);
                                message.success('설정값 변경에 성공하였습니다.');
                                setLoading(false);
                            })();
                        }}
                        defaultChecked={true}
                    />
                </Flex>
                <Flex justifyContent='space-between' style={{ marginBottom: 40 }}>
                    <Typography.Title level={5} style={{ margin: 0 }}>6. 무료 팔로워 자동화</Typography.Title>
                    <Switch
                        disabled={!settingStore.canAutoPilot}
                        checked={settingStore.autoPilotParameter?.insertFreePromotion}
                        onChange={checked => {
                            (async () => {
                                setLoading(true);
                                await settingStore.updateAutoPilotParameters('insertFreePromotion', checked);
                                message.success('설정값 변경에 성공하였습니다.');
                                setLoading(false);
                            })();
                        }}
                        defaultChecked={true}
                    />
                </Flex>
            </div>
            <Divider style={{ margin: '40px 0' }} />
            <div style={{ maxWidth: 400 }}>
                <Typography.Title level={3}>스케줄 제한</Typography.Title>
                <div>
                    <Typography.Title level={5}>좋아요(1 ~ 150)</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }}>
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP1}
                                min={Interval.SCHEDULE_LIMIT_LIKE_GROUP1.min}
                                max={Interval.SCHEDULE_LIMIT_LIKE_GROUP1.max}
                                marks={{
                                    [Interval.SCHEDULE_LIMIT_LIKE_GROUP1.min]: Interval.SCHEDULE_LIMIT_LIKE_GROUP1.min,
                                    [Interval.SCHEDULE_LIMIT_LIKE_GROUP1.max]: Interval.SCHEDULE_LIMIT_LIKE_GROUP1.max,
                                }}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_LIKE_GROUP1: value })}
                                value={magicValues?.SCHEDULE_LIMIT_LIKE_GROUP1}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP1}
                                min={Interval.SCHEDULE_LIMIT_LIKE_GROUP1.min}
                                max={Interval.SCHEDULE_LIMIT_LIKE_GROUP1.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.SCHEDULE_LIMIT_LIKE_GROUP1}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_LIKE_GROUP1: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP1) {
                                    if (
                                        settingStore.magicValues?.SCHEDULE_LIMIT_LIKE_GROUP1 !== magicValues?.SCHEDULE_LIMIT_LIKE_GROUP1 &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.SCHEDULE_LIMIT_LIKE_GROUP1} -> ${magicValues?.SCHEDULE_LIMIT_LIKE_GROUP1}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP1, `${magicValues?.SCHEDULE_LIMIT_LIKE_GROUP1}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP1);
                                }
                            }}>
                                { editingField === MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP1
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                    <Typography.Title level={5}>좋아요(151 ~ 400)</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }} >
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP2}
                                min={Interval.SCHEDULE_LIMIT_LIKE_GROUP2.min}
                                max={Interval.SCHEDULE_LIMIT_LIKE_GROUP2.max}
                                marks={{
                                    [Interval.SCHEDULE_LIMIT_LIKE_GROUP2.min]: Interval.SCHEDULE_LIMIT_LIKE_GROUP2.min,
                                    [Interval.SCHEDULE_LIMIT_LIKE_GROUP2.max]: Interval.SCHEDULE_LIMIT_LIKE_GROUP2.max,
                                }}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_LIKE_GROUP2: value })}
                                value={magicValues?.SCHEDULE_LIMIT_LIKE_GROUP2}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP2}
                                min={Interval.SCHEDULE_LIMIT_LIKE_GROUP2.min}
                                max={Interval.SCHEDULE_LIMIT_LIKE_GROUP2.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.SCHEDULE_LIMIT_LIKE_GROUP2}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_LIKE_GROUP2: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP2) {
                                    if (
                                        settingStore.magicValues?.SCHEDULE_LIMIT_LIKE_GROUP2 !== magicValues?.SCHEDULE_LIMIT_LIKE_GROUP2 &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.SCHEDULE_LIMIT_LIKE_GROUP2} -> ${magicValues?.SCHEDULE_LIMIT_LIKE_GROUP2}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP2, `${magicValues?.SCHEDULE_LIMIT_LIKE_GROUP2}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP2);
                                }
                            }}>
                                { editingField === MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP2
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                    <Typography.Title level={5}>좋아요(401 ~ 800)</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }} >
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP3}
                                min={Interval.SCHEDULE_LIMIT_LIKE_GROUP3.min}
                                max={Interval.SCHEDULE_LIMIT_LIKE_GROUP3.max}
                                marks={{
                                    [Interval.SCHEDULE_LIMIT_LIKE_GROUP3.min]: Interval.SCHEDULE_LIMIT_LIKE_GROUP3.min,
                                    [Interval.SCHEDULE_LIMIT_LIKE_GROUP3.max]: Interval.SCHEDULE_LIMIT_LIKE_GROUP3.max,
                                }}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_LIKE_GROUP3: value })}
                                value={magicValues?.SCHEDULE_LIMIT_LIKE_GROUP3}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP3}
                                min={Interval.SCHEDULE_LIMIT_LIKE_GROUP3.min}
                                max={Interval.SCHEDULE_LIMIT_LIKE_GROUP3.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.SCHEDULE_LIMIT_LIKE_GROUP3}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_LIKE_GROUP3: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP3) {
                                    if (
                                        settingStore.magicValues?.SCHEDULE_LIMIT_LIKE_GROUP3 !== magicValues?.SCHEDULE_LIMIT_LIKE_GROUP3 &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.SCHEDULE_LIMIT_LIKE_GROUP3} -> ${magicValues?.SCHEDULE_LIMIT_LIKE_GROUP3}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP3, `${magicValues?.SCHEDULE_LIMIT_LIKE_GROUP3}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP3);
                                }
                            }}>
                                { editingField === MagicValueType.SCHEDULE_LIMIT_LIKE_GROUP3
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                    <Typography.Title level={5}>좋아요(801 ~)</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }} >
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_LIKE_DEFAULT}
                                min={Interval.SCHEDULE_LIMIT_LIKE_DEFAULT.min}
                                max={Interval.SCHEDULE_LIMIT_LIKE_DEFAULT.max}
                                marks={{
                                    [Interval.SCHEDULE_LIMIT_LIKE_DEFAULT.min]: Interval.SCHEDULE_LIMIT_LIKE_DEFAULT.min,
                                    [Interval.SCHEDULE_LIMIT_LIKE_DEFAULT.max]: Interval.SCHEDULE_LIMIT_LIKE_DEFAULT.max,
                                }}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_LIKE_DEFAULT: value })}
                                value={magicValues?.SCHEDULE_LIMIT_LIKE_DEFAULT}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_LIKE_DEFAULT}
                                min={Interval.SCHEDULE_LIMIT_LIKE_DEFAULT.min}
                                max={Interval.SCHEDULE_LIMIT_LIKE_DEFAULT.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.SCHEDULE_LIMIT_LIKE_DEFAULT}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_LIKE_DEFAULT: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.SCHEDULE_LIMIT_LIKE_DEFAULT) {
                                    if (
                                        settingStore.magicValues?.SCHEDULE_LIMIT_LIKE_DEFAULT !== magicValues?.SCHEDULE_LIMIT_LIKE_DEFAULT &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.SCHEDULE_LIMIT_LIKE_DEFAULT} -> ${magicValues?.SCHEDULE_LIMIT_LIKE_DEFAULT}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.SCHEDULE_LIMIT_LIKE_DEFAULT, `${magicValues?.SCHEDULE_LIMIT_LIKE_DEFAULT}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.SCHEDULE_LIMIT_LIKE_DEFAULT);
                                }
                            }}>
                                { editingField === MagicValueType.SCHEDULE_LIMIT_LIKE_DEFAULT
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                    <Typography.Title level={5}>팔로우(1 ~ 100)</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }} >
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP1}
                                min={Interval.SCHEDULE_LIMIT_FOLLOW_GROUP1.min}
                                max={Interval.SCHEDULE_LIMIT_FOLLOW_GROUP1.max}
                                marks={{
                                    [Interval.SCHEDULE_LIMIT_FOLLOW_GROUP1.min]: Interval.SCHEDULE_LIMIT_FOLLOW_GROUP1.min,
                                    [Interval.SCHEDULE_LIMIT_FOLLOW_GROUP1.max]: Interval.SCHEDULE_LIMIT_FOLLOW_GROUP1.max,
                                }}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_FOLLOW_GROUP1: value })}
                                value={magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP1}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP1}
                                min={Interval.SCHEDULE_LIMIT_FOLLOW_GROUP1.min}
                                max={Interval.SCHEDULE_LIMIT_FOLLOW_GROUP1.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP1}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_FOLLOW_GROUP1: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP1) {
                                    if (
                                        settingStore.magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP1 !== magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP1 &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP1} -> ${magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP1}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP1, `${magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP1}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP1);
                                }
                            }}>
                                { editingField === MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP1
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                    <Typography.Title level={5}>팔로우(101 ~ 300)</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }} >
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP2}
                                min={Interval.SCHEDULE_LIMIT_FOLLOW_GROUP2.min}
                                max={Interval.SCHEDULE_LIMIT_FOLLOW_GROUP2.max}
                                marks={{
                                    [Interval.SCHEDULE_LIMIT_FOLLOW_GROUP2.min]: Interval.SCHEDULE_LIMIT_FOLLOW_GROUP2.min,
                                    [Interval.SCHEDULE_LIMIT_FOLLOW_GROUP2.max]: Interval.SCHEDULE_LIMIT_FOLLOW_GROUP2.max,
                                }}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_FOLLOW_GROUP2: value })}
                                value={magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP2}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP2}
                                min={Interval.SCHEDULE_LIMIT_FOLLOW_GROUP2.min}
                                max={Interval.SCHEDULE_LIMIT_FOLLOW_GROUP2.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP2}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_FOLLOW_GROUP2: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP2) {
                                    if (
                                        settingStore.magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP2 !== magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP2 &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP2} -> ${magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP2}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP2, `${magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP2}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP2);
                                }
                            }}>
                                { editingField === MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP2
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                    <Typography.Title level={5}>팔로우(301 ~ 600)</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }} >
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP3}
                                min={Interval.SCHEDULE_LIMIT_FOLLOW_GROUP3.min}
                                max={Interval.SCHEDULE_LIMIT_FOLLOW_GROUP3.max}
                                marks={{
                                    [Interval.SCHEDULE_LIMIT_FOLLOW_GROUP3.min]: Interval.SCHEDULE_LIMIT_FOLLOW_GROUP3.min,
                                    [Interval.SCHEDULE_LIMIT_FOLLOW_GROUP3.max]: Interval.SCHEDULE_LIMIT_FOLLOW_GROUP3.max,
                                }}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_FOLLOW_GROUP3: value })}
                                value={magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP3}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP3}
                                min={Interval.SCHEDULE_LIMIT_FOLLOW_GROUP3.min}
                                max={Interval.SCHEDULE_LIMIT_FOLLOW_GROUP3.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP3}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_FOLLOW_GROUP3: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP3) {
                                    if (
                                        settingStore.magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP3 !== magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP3 &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP3} -> ${magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP3}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP3, `${magicValues?.SCHEDULE_LIMIT_FOLLOW_GROUP3}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP3);
                                }
                            }}>
                                { editingField === MagicValueType.SCHEDULE_LIMIT_FOLLOW_GROUP3
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                    <Typography.Title level={5}>팔로우(601 ~)</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }} >
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_FOLLOW_DEFAULT}
                                min={Interval.SCHEDULE_LIMIT_FOLLOW_DEFAULT.min}
                                max={Interval.SCHEDULE_LIMIT_FOLLOW_DEFAULT.max}
                                marks={{
                                    [Interval.SCHEDULE_LIMIT_FOLLOW_DEFAULT.min]: Interval.SCHEDULE_LIMIT_FOLLOW_DEFAULT.min,
                                    [Interval.SCHEDULE_LIMIT_FOLLOW_DEFAULT.max]: Interval.SCHEDULE_LIMIT_FOLLOW_DEFAULT.max,
                                }}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_FOLLOW_DEFAULT: value })}
                                value={magicValues?.SCHEDULE_LIMIT_FOLLOW_DEFAULT}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.SCHEDULE_LIMIT_FOLLOW_DEFAULT}
                                min={Interval.SCHEDULE_LIMIT_FOLLOW_DEFAULT.min}
                                max={Interval.SCHEDULE_LIMIT_FOLLOW_DEFAULT.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.SCHEDULE_LIMIT_FOLLOW_DEFAULT}
                                onChange={value => handleValues({ SCHEDULE_LIMIT_FOLLOW_DEFAULT: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.SCHEDULE_LIMIT_FOLLOW_DEFAULT) {
                                    if (
                                        settingStore.magicValues?.SCHEDULE_LIMIT_FOLLOW_DEFAULT !== magicValues?.SCHEDULE_LIMIT_FOLLOW_DEFAULT &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.SCHEDULE_LIMIT_FOLLOW_DEFAULT} -> ${magicValues?.SCHEDULE_LIMIT_FOLLOW_DEFAULT}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.SCHEDULE_LIMIT_FOLLOW_DEFAULT, `${magicValues?.SCHEDULE_LIMIT_FOLLOW_DEFAULT}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.SCHEDULE_LIMIT_FOLLOW_DEFAULT);
                                }
                            }}>
                                { editingField === MagicValueType.SCHEDULE_LIMIT_FOLLOW_DEFAULT
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                </div>
            </div>
            <Divider style={{ margin: '40px 0' }} />
            <div style={{ maxWidth: 400 }}>
                <Typography.Title level={3}>셋업 워커</Typography.Title>
                <div>
                    <Typography.Title level={5}>1. 20분간 Aging 발행 수</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }}>
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.AGING_WEIGHT}
                                min={Interval.AGING_WEIGHT.min}
                                max={Interval.AGING_WEIGHT.max}
                                marks={{
                                    [Interval.AGING_WEIGHT.min]: Interval.AGING_WEIGHT.min,
                                    [Interval.AGING_WEIGHT.max]: Interval.AGING_WEIGHT.max,
                                }}
                                onChange={value => handleValues({ AGING_WEIGHT: value })}
                                value={magicValues?.AGING_WEIGHT}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.AGING_WEIGHT}
                                min={Interval.AGING_WEIGHT.min}
                                max={Interval.AGING_WEIGHT.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.AGING_WEIGHT}
                                onChange={value => handleValues({ AGING_WEIGHT: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.AGING_WEIGHT) {
                                    if (
                                        settingStore.magicValues?.AGING_WEIGHT !== magicValues?.AGING_WEIGHT &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.AGING_WEIGHT} -> ${magicValues?.AGING_WEIGHT}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.AGING_WEIGHT, `${magicValues?.AGING_WEIGHT}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.AGING_WEIGHT);
                                }
                            }}>
                                { editingField === MagicValueType.AGING_WEIGHT
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                    <Typography.Title level={5}>2. 20분간 Setting 발행 수</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }} >
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.SETTING_WEIGHT}
                                min={Interval.SETTING_WEIGHT.min}
                                max={Interval.SETTING_WEIGHT.max}
                                marks={{
                                    [Interval.SETTING_WEIGHT.min]: Interval.SETTING_WEIGHT.min,
                                    [Interval.SETTING_WEIGHT.max]: Interval.SETTING_WEIGHT.max,
                                }}
                                onChange={value => handleValues({ SETTING_WEIGHT: value })}
                                value={magicValues?.SETTING_WEIGHT}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.SETTING_WEIGHT}
                                min={Interval.SETTING_WEIGHT.min}
                                max={Interval.SETTING_WEIGHT.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.SETTING_WEIGHT}
                                onChange={value => handleValues({ SETTING_WEIGHT: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.SETTING_WEIGHT) {
                                    if (
                                        settingStore.magicValues?.SETTING_WEIGHT !== magicValues?.SETTING_WEIGHT &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.SETTING_WEIGHT} -> ${magicValues?.SETTING_WEIGHT}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.SETTING_WEIGHT, `${magicValues?.SETTING_WEIGHT}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.SETTING_WEIGHT);
                                }
                            }}>
                                { editingField === MagicValueType.SETTING_WEIGHT
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                    <Typography.Title level={5}>3. 20분간 Unlock 발행 수</Typography.Title>
                    <Flex justifyContent='space-between' style={{ marginBottom: 20 }} >
                        <div style={{ width: 150 }}>
                            <Slider
                                disabled={editingField !== MagicValueType.UNLOCK_WEIGHT}
                                min={Interval.UNLOCK_WEIGHT.min}
                                max={Interval.UNLOCK_WEIGHT.max}
                                marks={{
                                    [Interval.UNLOCK_WEIGHT.min]: Interval.UNLOCK_WEIGHT.min,
                                    [Interval.UNLOCK_WEIGHT.max]: Interval.UNLOCK_WEIGHT.max,
                                }}
                                onChange={value => handleValues({ UNLOCK_WEIGHT: value })}
                                value={magicValues?.UNLOCK_WEIGHT}
                            />
                        </div>
                        <Flex>
                            <InputNumber
                                disabled={editingField !== MagicValueType.UNLOCK_WEIGHT}
                                min={Interval.UNLOCK_WEIGHT.min}
                                max={Interval.UNLOCK_WEIGHT.max}
                                style={{ margin: '0 24px', maxWidth: 70 }}
                                value={magicValues?.UNLOCK_WEIGHT}
                                onChange={value => handleValues({ UNLOCK_WEIGHT: value ?? 0 })}
                            />
                            <EditButton onClick={async () => {
                                if (editingField === MagicValueType.UNLOCK_WEIGHT) {
                                    if (
                                        settingStore.magicValues?.UNLOCK_WEIGHT !== magicValues?.UNLOCK_WEIGHT &&
                                        window.confirm(`설정값을 변경하시겠습니까? ${settingStore.magicValues?.UNLOCK_WEIGHT} -> ${magicValues?.UNLOCK_WEIGHT}`)
                                    ) {
                                        try {
                                            setLoading(true);
                                            await settingStore.updateMagicValues(MagicValueType.UNLOCK_WEIGHT, `${magicValues?.UNLOCK_WEIGHT}`);
                                            message.success('설정값 변경에 성공하였습니다.');
                                            setLoading(false);
                                        } catch (err) {
                                            message.error('설정값 변경에 실패하였습니다.');
                                            setLoading(false);
                                        }
                                    }
                                    setEditingField(undefined);
                                } else {
                                    setEditingField(MagicValueType.UNLOCK_WEIGHT);
                                }
                            }}>
                                { editingField === MagicValueType.UNLOCK_WEIGHT
                                    ? <CheckOutlined />
                                    : <EditOutlined />
                                }
                            </EditButton>
                        </Flex>
                    </Flex>
                </div>
            </div>
        </div>
    );
});

export default Setting;